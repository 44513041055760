import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./Pages/Home";
// import About from "./Pages/About";
// import Service from "./Pages/Service";
import img1 from "./assets/images/coming soon.jpg";
import "./App.css";
import Layout from "./components/UI-Layout/Layout";

function App() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Home />} />
    //     <Route path="/about" element={<About />} />
    //     <Route path="/service" element={<Service />} />
    //   </Routes>
    // </Router>
    // <>
    //   <Home />
    //   <About />
    //   <Service />
    // </>
    <Layout>
      <div className="undermaintenance">
        <div className="text">
          <h2 className="oops">OOPS!</h2>
          <p className="maintenance">Website under maintenance</p>
        </div>
        <div className="image">
          <img src={img1} className="main-image" alt="maintenance-image" />
        </div>
      </div>
    </Layout>
  );
}

export default App;
