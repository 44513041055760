import React, { useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Wrapper from "../UI-Layout/Wrapper";
import comunaLogo from "../../../src/assets/images/logo2-rbg.png";
import "./topnavbar.css";

const TopNavbar = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const AboutShowDropdown = (e) => {
    setShowAbout(true);
  };

  const AboutHideDropdown = (e) => {
    setShowAbout(false);
  };
  const ServiceShowDropdown = (e) => {
    setShowService(true);
  };

  const ServiceHideDropdown = (e) => {
    setShowService(false);
  };
  const ContactShowDropdown = (e) => {
    setShowContact(true);
  };

  const ContactHideDropdown = (e) => {
    setShowContact(false);
  };

  return (
    <div className="topnavbar-wrapper">
      <Navbar className="navbar-items">
        <Container fluid>
          <Nav>
            <Navbar.Brand href="/">
              <img src={comunaLogo} alt="comuna-logo" className="comuna-logo" />
            </Navbar.Brand>
          </Nav>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          {/* <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="topnav-items">
              <NavDropdown
                to="/about"
                className="topnav-link"
                // id="collasible-nav-dropdown"
                show={showAbout}
                onMouseEnter={AboutShowDropdown}
                onMouseLeave={AboutHideDropdown}
                title="About"
              >
                <NavDropdown.Item>The Company</NavDropdown.Item>
                <NavDropdown.Item>Leadership Team</NavDropdown.Item>
                <NavDropdown.Item>CSR</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                to="/about"
                className="topnav-link"
                // id="collasible-nav-dropdown"
                show={showService}
                onMouseEnter={ServiceShowDropdown}
                onMouseLeave={ServiceHideDropdown}
                title="Services"
              >
                <NavDropdown.Item>The Company</NavDropdown.Item>
                <NavDropdown.Item>Leadership Team</NavDropdown.Item>
                <NavDropdown.Item>CSR</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                to="/about"
                className="topnav-link"
                // id="collasible-nav-dropdown"
                show={showContact}
                onMouseEnter={ContactShowDropdown}
                onMouseLeave={ContactHideDropdown}
                title="Contact us"
              >
                <NavDropdown.Item>The Company</NavDropdown.Item>
                <NavDropdown.Item>Leadership Team</NavDropdown.Item>
                <NavDropdown.Item>CSR</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </div>
  );
};

export default TopNavbar;
