import React from "react";
import TopNavbar from "../Navbar/TopNavbar";

const Layout = ({ children }) => {
  return (
    <>
      <TopNavbar />
      {children}
    </>
  );
};

export default Layout;
